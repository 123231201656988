.App {
  text-align: center;
}

.headerLTW {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: padding-top 0.3s ease-in-out;
  -moz-transition: padding-top 0.3s ease-in-out;
  transition: padding-top 0.3s ease-in-out;
  width: 100%;
  min-height: 5.056em;
  padding-top: 1.667em;
  padding-bottom: 1.667em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #e5e5e5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  z-index: 999;
  background-color: white;
}

.headerLine {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100em;
  min-height: 1.667em;
  margin: 0 auto;
}

.headerLTW .logo-wrap {
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  margin-right: 2.500em;
}

.logo-wrap {
  font-size: 1.333em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #232323;
  line-height: 1;
}

#gebtech {
  position: absolute;
  top: 45px;
  left: 20px;
  z-index: 999999;
  border-radius: 0;
}

.headerLTW img,
.mobile-menu img {
  display: inline-block;
  vertical-align: initial;
  -webkit-transition: max-height .3s ease-in-out;
  -moz-transition: max-height .3s ease-in-out;
  -o-transition: max-height .3s ease-in-out;
  transition: max-height .3s ease-in-out;
  -webkit-backface-visibility: hidden;
}

img,
figure img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  height: auto;
  display: block;
  border-radius: 6px;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

img {
  border: 0;
}

.mainMenu {
  display: flex;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  color: #232323 !important;
  text-decoration: none;
  font-size: 3.222em;;
}

